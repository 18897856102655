<template>
  <div class="serviceProductDetails">
    <Breadcrumb :data="nav" :is$t="true" />
    <div class="container">
      <div class="top">
        <div class="title">{{ detail.name }}</div>
        <div class="concat" @click="toContact">
          <i class="el-icon-phone"></i> 联系我们
        </div>
      </div>
      <div class="bottom">
        <div class="details">
          <div class="item">
            <span class="label">产品名称：</span><span class="value">{{ detail.name }}</span>
          </div>
          <div class="item">
            <span class="label">产品类别：</span><span class="value">{{ detail.type }}</span>
          </div>
          <div class="item">
            <span class="label">服务国家：</span><span class="value">{{ detail.country }}</span>
          </div>
          <div class="item">
            <span class="label">服务对象：</span><span class="value">{{ detail.productApplication }}</span>
          </div>
          <div class="item">
            <span class="label">服务内容：</span><span
              class="value"
            ><div class="ql-editor" v-html="detail.serviceContent"></div></span>
          </div>
        </div>
        <div class="listWarpTitle">服务团队</div>
        <div v-if="list.length">
          <div class="listWarp">
            <div v-for="item in list" :key="item.id" class="listItem">
              <img class="cover" :src="item.logo || defaultImg" alt="" />
              <div class="right">
                <div class="groupTitle">{{ item.name }}</div>
                <div class="content">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <Pagination
            class="pagination"
            :page-info="pageInfo"
            @changePage="changePage"
          />
        </div>
        <NoData v-else type="content" size="medium" class="no-data" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import NoData from "@/components/NoData"
import Breadcrumb from "@/components/Breadcrumb"
import API from "@/api/lawServiceProduct"
export default {
  name: "ServiceProductDetails",
  components: {
    Pagination,
    Breadcrumb,
    NoData,
    API,
  },
  data() {
    return {
      defaultImg: require("@/assets/serviceProduct/default_img.png"),
      detail: {},
      list: [],
      id: null,
      pageInfo: {
        page: 1,
        size: 4,
        total: 100,
      },
    }
  },
  computed: {
    nav() {
      return [
        { name: "首页", path: "/home" },
        { name: "法律服务产品", path: "/lawServiceProduct/list" },
        { name: this.detail.name, path: "" },
      ]
    },
  },
  created() {
    const id = this.$route.query?.id || ""
    if (id) {
      this.id = id
    }
    this.getTeams()
    this.getDetails()
  },
  methods: {
    getTeams() {
      this.loading = true
      const {
        pageInfo: { size, currentPage: page },
      } = this
      console.log(size, page, 1111111)
      API.getLegalServiceProductTeams(this.id, {
        size,
        page: page || 1,
      }).then((res) => {
        this.list = res.content
        this.loading = false
        this.pageInfo.total = res.page.total
      })
    },
    getDetails() {
      API.getLegalServiceProductDetails(this.id).then((res) => {
        this.detail = res
      })
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getTeams()
    },
    toContact() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfca486508c060d890c"
    },
  },
}
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.serviceProductDetails {
  width: 1200px;
  min-width: 1200px;
  margin: auto;

  .container {
    width: 1200px;
    min-width: 1200px;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
    .top {
      padding: 18px 30px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .concat {
        width: 160px;
        min-width: 160px;
        height: 50px;
        line-height: 50px;
        background: #00a4ff;
        border-radius: 30px;
        color: #fff;
        font-size: 20px;
        margin-left: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
    .bottom {
      padding: 30px;
    }

    .details {
      .item {
        font-size: 14px;
        margin-bottom: 24px;
        display: flex;
        align-items: baseline;
        .label {
          color: #999999;
          font-weight: 400;
          min-width: 80px;
        }
        .value {
          color: #333333;
          line-height: 22px;
        }
      }
    }
  }

  .listWarpTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin: 40px 0 20px;
    &::before {
      content: "";
      width: 4px;
      height: 16px;
      background: #00a4ff;
      display: inline-block;
      margin-right: 12px;
    }
  }

  .listWarp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .listItem {
      padding: 30px;
      margin-bottom: 30px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
      border: 1px solid #e5e8ed;
      display: flex;
      .cover {
        width: 86px;
        height: 86px;
        min-width: 86px;
        margin-right: 20px;
      }
      .groupTitle {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        word-wrap: break-word;
        line-height: 24px;
      }
    }
  }
}
</style>
